import { BaseEntity } from './base-entity';
import { FileMedia } from './media.model';
import { ThemePalette } from '@angular/material/core';
import { Tag } from './tag.model';
import { Group } from './group.model';

export enum AssetStatus {
  PUBLIC = 'public',
  PRIVATE = 'private',
  PROTECTED = 'protected',
  BLOCKED = 'blocked',
  ARCHIVE = 'archive',
  TRASH = 'trash'
}

export enum AssetType {
  IMAGE = 'image',
  VIDEO = 'video',
  TYPE_3D = '3d',
  SOUND = 'sound',
  MULTIPLE = 'multiple'
}

export enum GroupAssetStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  BLOCKED = 'blocked'
}

export enum AssetNoteStatus {
  PUBLIC = 'public',
  PRIVATE = 'private'
}

export interface GroupAssetParameter {
  status: GroupAssetStatus;
  type: AssetType;
}

export class AssetMarker {
  id: number;
  title: string;
  media_urls: FileMedia;
  meta: {
    scale: number;
    rotate: number[];
    position: number[];
  };
}

export class AssetNote {
  id: number;
  asset_id?: number;
  title: string;
  content: string;
  status: AssetNoteStatus;
  meta: {
    anchor?: number[];
    interval: number[];
    position: number[];
  };
}

export class Asset extends BaseEntity {
  title: string;
  status: AssetStatus;
  user_id: number;
  type: AssetType;
  media: string;
  media_urls: FileMedia;
  preview: string;
  preview_urls: FileMedia;
  tag_list: Tag[];
  group_ids: Group[];
  group_asset_parameters: GroupAssetParameter;
  marker_media_url: string;
  marker_list: AssetMarker[];
  notes: AssetNote[];
}

export const AssetStates = [
  { name_key: 'ASSETS.STATES.PUBLIC', value: AssetStatus.PUBLIC, color: 'primary' as ThemePalette },
  { name_key: 'ASSETS.STATES.PRIVATE', value: AssetStatus.PRIVATE, color: 'secondary' as ThemePalette },
  { name_key: 'ASSETS.STATES.PROTECTED', value: AssetStatus.PROTECTED, color: 'secondary' as ThemePalette },
  { name_key: 'ASSETS.STATES.BLOCKED', value: AssetStatus.BLOCKED, color: 'warn' as ThemePalette },
  { name_key: 'ASSETS.STATES.ARCHIVE', value: AssetStatus.ARCHIVE, color: 'accent' as ThemePalette },
  { name_key: 'ASSETS.STATES.TRASH', value: AssetStatus.TRASH, color: 'warn' as ThemePalette }
];

export const AssetTypes = [
  { name_key: 'ASSETS.TYPES.IMAGE', value: AssetType.IMAGE, icon: 'insert_photo', color: 'accent' as ThemePalette },
  { name_key: 'ASSETS.TYPES.VIDEO', value: AssetType.VIDEO, icon: 'videocam', color: 'warn' as ThemePalette },
  { name_key: 'ASSETS.TYPES.TYPE_3D', value: AssetType.TYPE_3D, icon: '3d_rotation', color: 'primary' as ThemePalette },
  { name_key: 'ASSETS.TYPES.TYPE_SOUND', value: AssetType.SOUND, icon: 'volume_up', color: 'primary' as ThemePalette }
];

export const GroupAssetStates = [
  { name_key: 'ASSETS.STATES.ACTIVE', value: GroupAssetStatus.ACTIVE, color: 'primary' as ThemePalette },
  { name_key: 'ASSETS.STATES.PENDING', value: GroupAssetStatus.PENDING, color: 'accent' as ThemePalette },
  { name_key: 'ASSETS.STATES.BLOCKED', value: GroupAssetStatus.BLOCKED, color: 'warn' as ThemePalette }
];
