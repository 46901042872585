import { BaseEntity } from './base-entity';
import { ThemePalette } from '@angular/material/core';
import { FileMedia } from './media.model';

export enum TagType {
  KEYWORD = 'keyword',
  CATEGORY = 'category',
  REASON = 'reason',
  YEAR = 'year',
  SUBJECT = 'subject'
}

export enum TagStatus {
  PRIVATE = 'private',
  PUBLIC = 'public',
  TRASH = 'trash'
}

export class Tag extends BaseEntity {
  title: string;
  type: TagType;
  status: TagStatus;
  description: string;
  cover: string;
  cover_urls: FileMedia;
  tag_id: number;
}

export const TagTypes = [
  { name_key: 'TAGS.TYPES.KEYWORD', value: TagType.KEYWORD, color: 'default' as ThemePalette },
  { name_key: 'TAGS.TYPES.CATEGORY', value: TagType.CATEGORY, color: 'secondary' as ThemePalette},
  { name_key: 'TAGS.TYPES.REASON', value: TagType.REASON, color: 'primary' as ThemePalette },
  { name_key: 'TAGS.TYPES.YEAR', value: TagType.YEAR, color: 'warn' as ThemePalette },
  { name_key: 'TAGS.TYPES.SUBJECT', value: TagType.SUBJECT, color: 'accent' as ThemePalette },
];

export const TagStates = [
  { name_key: 'TAGS.STATES.PRIVATE', value: TagStatus.PRIVATE, color: 'default' as ThemePalette },
  { name_key: 'TAGS.STATES.PUBLIC', value: TagStatus.PUBLIC, color: 'primary' as ThemePalette },
  { name_key: 'TAGS.STATES.TRASH', value: TagStatus.TRASH, color: 'warn' as ThemePalette }
];
