import { BaseEntity } from './base-entity';
import { User } from './user.model';
import { ThemePalette } from '@angular/material/core';

export class School extends BaseEntity {
  name: string;
  title: string;
  om_number: string;
  zipcode: string;
  city: string;
  address: string;
  teacher_count: string;
  group_count: string;
  asset_count: string;
  student_asset_count: string;
  operator: User;
  user_list: User[];
}

export enum SchoolUserStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  BLOCKED = 'blocked'
}

export enum SchoolUserType {
  OPERATOR = 'operator',
  TEACHER = 'teacher',
  STUDENT = 'student'
}

export class SchoolUserResponse {
  school_id: number;
  type: SchoolUserType;
  status: SchoolUserStatus;
  expired_at: string;
  user: User
}

export const SchoolUserStates = [
  { name_key: 'USERS.STATES.ACTIVE', value: SchoolUserStatus.ACTIVE, color: 'primary' as ThemePalette },
  { name_key: 'USERS.STATES.PENDING', value: SchoolUserStatus.PENDING, color: 'accent' as ThemePalette },
  { name_key: 'USERS.STATES.BLOCKED', value: SchoolUserStatus.BLOCKED, color: 'warn' as ThemePalette }
];

export const SchoolUserTypes = [
  { name_key: 'USERS.USER_TYPES.OPERATOR', value: SchoolUserType.OPERATOR, color: 'warn' as ThemePalette },
  { name_key: 'USERS.USER_TYPES.TEACHER', value: SchoolUserType.TEACHER, color: 'secondary' as ThemePalette },
  { name_key: 'USERS.USER_TYPES.STUDENT', value: SchoolUserType.STUDENT, color: 'accent' as ThemePalette }
];
