import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IEntity } from '../../models/base-entity';
import { ListService } from '../../services/list/list.service';
import { ItemResponse } from '../../models/api/item.response';
import { EduardoFormControl } from '../form-input/wrapper';
import { AppModule } from '../../../app.module';
import { showErrorMessage } from '../../errorHelper';
import { MODEL_CONFIG, ModelEditorService } from '../../services/model-editor.service';
import { ActionType } from '../../models/permission.model';
import { AppLoaderService } from '../../services/app-loader/app-loader.service';
import { finalize } from 'rxjs/operators';
import { CustomReuseStrategy } from '../../helpers/route-reuse-strategy';

export type ModelType = IEntity;

export abstract class DetailComponent<TModel extends ModelType> implements OnInit, OnDestroy {
  public form: FormGroup;

  private _item: TModel = null;
  public get item(): TModel {
    return this._item;
  }

  public set item(value: TModel) {
    this._item = value;
    this.editorService.model = value;
  }

  protected readonly loaderService: AppLoaderService;
  protected readonly editorService: ModelEditorService<TModel>;

  public location: Location;
  public router: Router;
  public activatedRoute: ActivatedRoute;
  public fb: FormBuilder;
  public loadedItem = new Subject<boolean>();
  public routeReuse: RouteReuseStrategy;

  protected constructor(public service: ListService<TModel>, public injector: Injector, public ref: ChangeDetectorRef) {
    this.fb = injector.get(FormBuilder);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.location = injector.get(Location);
    this.loaderService = AppModule.injector.get(AppLoaderService);
    this.routeReuse = injector.get(RouteReuseStrategy);

    this.editorService = injector.get(ModelEditorService);
    this.editorService.modelConfig = injector.get(MODEL_CONFIG);
    this.editorService.action = this.getItemId() ? ActionType.Update : ActionType.Create;
  }

  public getItemRequest(id): Observable<ItemResponse<TModel>> {
    return this.service.getById(id);
  }

  public getItemId(): number | null {
    const {id} = this.activatedRoute.snapshot.params;
    return id !== '0' ? Number.parseInt(id) : null;
  }

  public ngOnInit() {
    this.getItems();
  }

  public ngOnDestroy() {
    this.editorService.model = null;
    this.editorService.modelConfig = null;
  }

  public getItems() {
    // lekérdezzük az elemet ha van azonosító
    const id = this.getItemId();
    if (id) {
      this.getItemRequest(id).subscribe(response => {
        this.item = response.result;
        this.onItemReceived(this.item);
        this.form.patchValue(this.item);
        this.loadedItem.next(true);
        this.ref.detectChanges();
      });
    } else {
      this.item = {id: null} as TModel;
    }
  }

  public isLoadedItem(): Observable<boolean> {
    return this.loadedItem.asObservable();
  }

  protected refreshItem(newItem: TModel) {
    this.item.id = newItem.id;
    this.form.patchValue(newItem);
  }

  public validateForm(): boolean {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({onlySelf: true});
      });

      this.form.updateValueAndValidity();
      return false;
    }

    return true;
  }

  protected setRequiredFields(context: { [key: string]: string[] }) {
    if (context) {
      const keys = Object.keys(context);
      keys.forEach(key => {
        const formField = this.form.get(key) as EduardoFormControl;
        if (formField) {
          formField.setValidators([Validators.required]);
          formField.markRequired = true;
        }
      });
    }
  }

  public save(withExit: boolean): Observable<boolean> | null {
    if (!this.validateForm()) {
      return null;
    }

    this.loaderService.open('Folyamatban..');

    const data = this.postProcessData(Object.assign({}, this.form.getRawValue()));
    const isCreate = this.editorService.isCreating;
    const saveObs = new Subject<boolean>();
    const observable: Observable<ItemResponse<TModel>> = isCreate ?
      this.service.create(data) :
      this.service.update(data);

    observable.pipe(finalize(() => this.loaderService.close())).subscribe(
        ((value) => {
          this.refreshItem(value.result);
          if (withExit) {
            this.saveCompleteWithExit(value, isCreate);
          } else {
            this.saveComplete(value, isCreate);
            if (isCreate) {
              const urlParts = this.router.url.split('/').slice(0, -1);
              this.router.navigate([...urlParts, this.item.id], {
                replaceUrl: true,
              });
            }
          }
          this.clearRouteHandlers();
          saveObs.next(true);
        }),
        ((error) => {
          this.handleError(error);
          saveObs.next(false);
        })
    );

    return saveObs;
  }

  protected clearRouteHandlers(extraItem?: string): any {
    // Mentés esetén újratöltjük a listát (töröljük a mentett route Map-ből az entitást)
    const entityRouteName = this.router.url.split('/').slice(0, -1)[1];
    return (<CustomReuseStrategy>this.routeReuse).clearHandlers(entityRouteName, extraItem);
  }

  protected onItemReceived(data: TModel) {
    this.editorService.model = data;
  }

  protected postProcessData(data: any): any {
    return data;
  }

  protected handleError(httpError: HttpErrorResponse) {
    console.log(httpError);
    // apiból kapott hiba
    const error: { message: string, context: { [key: string]: string[] } } = httpError.error;

    // általános hibaüzenet megjelenítése
    showErrorMessage(httpError);

    const context = error.context;
    if (context) {
      const keys = Object.keys(context);
      keys.forEach(key => {
        const formField = this.form.get(key);
        if (formField) {
          formField.setErrors({
            error: context[key].join(', ')
          });
        }
      });

      // touchednek jelöljük, mert enélkül csak focus után jelenne meg a hibajelzés
      this.form.markAllAsTouched();
    }
  }

  protected saveComplete(value: ItemResponse<TModel>, isCreating?: boolean): void {
    AppModule.showMessage(`Sikeresen ${isCreating ? 'létrehozva' : 'módosítva'}`);
  }

  protected saveCompleteWithExit(value: ItemResponse<TModel>, isCreating?: boolean): void {
    AppModule.showMessage(`Sikeresen ${isCreating ? 'létrehozva' : 'módosítva'}`);
    this.location.back();
  }

  protected deleteItemCommand(): Observable<any> {
    return (this.service as ListService<TModel>).delete(this.item.id);
  }

  public delete() {
    if (window.confirm('Biztos, hogy törlöd?')) {
      this.deleteItemCommand().subscribe(
          () => {
            AppModule.showMessage('Sikeresen törölve');
            this.location.back();
          },
          (error) => this.handleError(error));
    }
  }

  public setInputsDisable(disable: string[], formControls: Object) {
    for (const [key] of Object.entries(formControls)) {
      if (!disable.includes(key)) {
        formControls[key].enable();
      } else {
        formControls[key].disable();
      }
    }
  }
}
